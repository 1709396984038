<template>
  <div id="membership-guideline">
    <b-container class="base-container-x member-park-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading === true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading === true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading === false" class="col-12 mb-5 pt-4">
        <div>
          <h1 class="text-center">{{ $t('Membership Guidelines') }}</h1>
           <p class="mt-3">{{ $t('World Yoga Alliance was founded in November 2011. We are currently supporting our members over 80 countries worldwide. We believe in quality and we will be more than happy to welcome you as part of WYA community. Join World Yoga Alliance and get registered as RSY (Registered School of Yoga), RTY (Registered Teacher of Yoga) or other specific designations to receive lifetime support and enhance infinite possibilities in your yoga business.') }}</p>
          <div class="text-center">
           <p class="mb-0">{{ $t('Unity in Diversity') }}</p>
           <p class="mb-0">{{ $t('One Passion, One Community, One Place.') }}</p>
           <p>{{ $t('World Yoga Aliiance®') }}</p>
          </div>
          <hr/>
        </div>
        <div >
          <h4 class="font-weight-bold text-center">{{ $t('Registration Process') }}</h4>
          <p class="text-center">{{ $t('For those who are new to our organization. Here are the steps to become our members.') }}</p>
          <b-row class="d-flex justify-content-between">
            <b-col cols="12" sm="3" md="6" lg="2">
              <div class="pointer step1 d-flex justify-content-center align-items-center">
                <h5 class="text-uppercase text-center font-weight-bold text-white mb-0">{{ $t('Step 01') }}</h5>
              </div>
              <div class="mt-3">
                <p class="header font-weight-bold text-uppercase text-center">{{ $t('Sign up') }}</p>
                <p class="desc text-14">{{ $t('First, you will be required to') }} <router-link :to="{ name: 'authenSignup' }">{{ $t('sign up') }}</router-link> {{ $t('and then login to your account.') }}</p>
              </div>
            </b-col>
            <b-col cols="12" sm="3" md="6" lg="2" class="mt-sm-5">
              <div class="pointer step2 d-flex justify-content-center align-items-center">
                <h5 class="text-uppercase text-center font-weight-bold text-white mb-0">{{ $t('Step 02') }}</h5>
              </div>
              <div class="mt-3">
                <p class="header font-weight-bold text-uppercase text-center">{{ $t('Submit the application form') }}</p>
                <p class="desc text-14">{{ $t('Submit School or Teacher online application along with the compulsory documents from the menu in your profile account.') }}</p>
              </div>
            </b-col>
            <b-col cols="12" sm="3" md="6" lg="2">
              <div class="pointer step3 d-flex justify-content-center align-items-center">
                <h5 class="text-uppercase text-center font-weight-bold text-white mb-0">{{ $t('Step 03') }}</h5>
              </div>
              <div class="mt-3">
                <p class="header font-weight-bold text-uppercase text-center">{{ $t('Wait for review') }}</p>
                <p class="desc text-14">{{ $t('Wait for an application to be reviewed. During this time, our team may contact you in case we need further information and clarification. The reviewing process could take up to two weeks.') }}</p>
              </div>
            </b-col>
            <b-col cols="12" sm="3" md="6" lg="2" class="mt-sm-5">
              <div class="pointer step4 d-flex justify-content-center align-items-center">
                <h5 class="text-uppercase text-center font-weight-bold text-white mb-0">{{ $t('Step 04') }}</h5>
              </div>
              <div class="mt-3">
                <p class="header font-weight-bold text-uppercase text-center">{{ $t('Pay the fee') }}</p>
              <p class="desc text-14">{{ $t('Once your application is approved, you will be required to submit ') }} <router-link :to="{ name: 'feeDetails' }">{{ $t('the registration fee.') }}</router-link></p>
              </div>
            </b-col>
            <b-col cols="12" sm="3" md="6" lg="2">
              <div class="pointer step5 d-flex justify-content-center align-items-center">
                <h5 class="text-uppercase text-center font-weight-bold text-white mb-0">{{ $t('Step 05') }}</h5>
              </div>
              <div class="mt-3">
                <p class="header font-weight-bold text-uppercase text-center">{{ $t('Get your registration license') }}</p>
                <p class="desc text-14">{{ $t('Receive registration license via email. Your school/teacher registration will be listed on our ') }} <router-link :to="{ name: 'searchDirectory' }">{{ $t('International Directory.') }}</router-link></p>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="learn-more-section">
          <h4 class="font-weight-bold text-center">{{ $t('Learn More About Our Registration') }}</h4>
          <b-row class="mt-3">
            <b-col cols="12" md="4" class="mb-5 mb-sm-0">
              <b-img class="w-100" :src="require('../../assets/images/membership_guidelines/guideline_our_standards.jpeg')"/>
              <div class="learn-more-bottom text-center pt-3 pb-3">
                <h5 class="text-uppercase text-primary-light mb-0">{{ $t('Our Standards') }}</h5>
                <b-button pill variant="link" :to="{ name: 'standards' }" class="text-decoration-none text-primary-dark text-14 pt-0">{{ $t('Learn More') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
              </div>
            </b-col>
            <b-col cols="12" md="4" class="mb-5 mb-sm-0">
              <b-img class="w-100" :src="require('../../assets/images/membership_guidelines/guideline_benefits.jpeg')"/>
              <div class="learn-more-bottom text-center pt-3 pb-3">
                <h5 class="text-uppercase text-primary-light mb-0">{{ $t('Member Benefits') }}</h5>
                <b-button pill variant="link" :to="{ name: 'memberBenefit' }" class="text-decoration-none text-primary-dark text-14 pt-0">{{ $t('Learn More') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
              </div>
            </b-col>
            <b-col cols="12" md="4">
              <b-img class="w-100" :src="require('../../assets/images/membership_guidelines/guideline_fee.jpeg')"/>
               <div class="learn-more-bottom text-center pt-3 pb-3">
                <h5 class="text-uppercase text-primary-light mb-0">{{ $t('Fee Details') }}</h5>
                <b-button pill variant="link" :to="{ name: 'feeDetails' }" class="text-decoration-none text-primary-dark text-14 pt-0">{{ $t('Learn More') }} <font-awesome-icon :icon="['fas', 'angle-right']"/></b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'MembershipGuideline',
  title: ' | Membership Guideline',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #membership-guideline {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
hr {
  border: 2px solid black;
  width: 15rem;
}
.pointer {
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 50px;
  position: relative;
}
.pointer.step1 {
  background: #54b8f3;
}
.pointer.step2 {
  background: #f3d131;
}
.pointer.step3 {
  background: #a4c92c;
}
.pointer.step4 {
  background: #f1541f;
}
.pointer.step5 {
  background: #f38306;
}
.pointer:before {
  content: "";
  position: absolute;
  right: -24px;
  width: 0;
  height: 0;
  border-left: 25px solid red;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
}
.pointer.step1:before{
  border-left: 25px solid #54b8f3;
}
.pointer.step2:before{
  border-left: 25px solid #f3d131;
}
.pointer.step3:before{
  border-left: 25px solid #a4c92c;
}
.pointer.step4:before{
  border-left: 25px solid #f1541f;
}
.pointer.step5:before{
  border-left: 25px solid #f38306;
}
.learn-more-section {
  background-color: #f3f3f3;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.learn-more-bottom {
  background-color: white;
}
.learn-more-bottom a.btn:hover {
  color: red!important;
}
</style>
